import React from 'react';
import { Link } from 'gatsby';

import Layout from 'src/components/layout/main';
import Section from 'src/components/base/section';
import HeroTitle from 'src/components/hero/title';
import { ImageContain } from 'src/components/base/image';
import LinkExternal from 'src/components/base/linkExternal';
import Capa from 'src/components/portfolio/portfolio';

import cohoRel from "src/images/portfolio/coho-rel.png";
import waldo from "src/images/portfolio/waldo.png";
import steelhead from "src/images/portfolio/steelhead.png";
import gatorbar from "src/images/portfolio/gatorbar.png";


const PortfolioPage = (props) => {


  return (
    <Layout page="/portfolio">
      {/* Top Header */}
      <Section color="dark" bottom="4rem">
        <HeroTitle>Portfolio</HeroTitle>
      </Section>

      <Capa.item dark reverse>
        <Capa.content>
          <ImageContain src={steelhead} alt="Steelhead logo" />
        </Capa.content>
        <Capa.content>
          <Capa.title>Steelhead</Capa.title>
          <p>
            <LinkExternal href="https://gosteelhead.com/">Steelhead</LinkExternal> is a factory planning and execution software for surface finishing facilities, co-founded in June of 2021 by Laurium Labs, <LinkExternal href="https://raysquaredsolutions.com/">Ray Squared</LinkExternal>, and <LinkExternal href="https://www.analytech-solutions.com/analytech-solutions/welcome.html">Analytech Solutions</LinkExternal>.
            The Steelhead software allows factory managers to input their Work Orders, and Steelhead generates a plan that Operators follow.
            Every Operator moves the Work Orders along as they complete steps in the process.
            Steelhead provides advanced process creation abilities in a tree data structure, which allows for de-duplication of sub-processes, resulting in better accuracy and flexibility.
            Additionally, Steelhead's customer portals allows for customers to view real-time Purchase Order status without an email or phone call.
          </p>

        </Capa.content>
      </Capa.item>


      <Capa.item>
        <Capa.content>
          <ImageContain src={cohoRel} alt="Coho REL Logo" />
        </Capa.content>
        <Capa.content>
          <Capa.title>Coho</Capa.title>
          <p>
            Coho is a motion planning software for industrial dip and conveyor lines.
            Laurium Labs built Coho under contract for <LinkExternal href="https://www.relinc.com/">REL Inc.</LinkExternal> after traditional PLC programming fell short for the complex optimization task of planning motion when there are nearly infinite possibilities.
            Built in the <LinkExternal href="https://julialang.org/">Julia</LinkExternal> programming language, the core of Coho was developed in under 2 years and became a market leader, surpassing companies that have been grinding on the problem for 30 years in C++.
            Laurium Labs developed both the breakthrough planning algorithm along with the database and user interface that provides all the information and flexibility required to run parts through these lines.
            Coho gives REL a clear market advantage for selling equipment and control systems with the best-in-class control software.
          </p>
          <p>
            In addition to developing core technology, Laurium Labs contributes to sales meetings, system design, onsite deployments, and support. Visit our <Link to="/blog/coho">blog post</Link> to learn more!
          </p>
        </Capa.content>
      </Capa.item>


      <Capa.item dark reverse>
        <Capa.content>
          <ImageContain src={waldo} alt="waldo logo" />
        </Capa.content>
        <Capa.content>
          <Capa.title>Waldo</Capa.title>
          <p>
            <LinkExternal href="https://waldologs.com/">Waldo</LinkExternal> is a SaaS platform for the timber harvest industry.
            Laurium Labs developed Waldo on a cash/equity deal, with the first line of code forged in November 2019 and deployment on the App Stores in January of 2020.
            Laurium Labs has also contributed to general company strategy including investment discussions, sales, and marketing.
            Waldo has dreams of being the modern platform for logging, and Laurium Labs is building the technology to make it all possible.
          </p>
        </Capa.content>
      </Capa.item>




      <Capa.item>
        <Capa.content>
          <ImageContain src={gatorbar} alt="gator bar loukus" />
        </Capa.content>
        <Capa.content>
          <Capa.title>GatorBar</Capa.title>
          <p>
            Extruding molten glass through a 1/4" hole to create 21st century rebar currently has an economic conundrum.
            The bushing through which molten glass is pushed is made out of Rhodium, which is more expensive than Gold.
            In coordinated efforts with <LinkExternal href="https://www.neuvokascorp.com/">Gator Bar</LinkExternal> and <LinkExternal href="https://www.loukus.com/">Loukus Technologies</LinkExternal>, Laurium Labs is helping with the effort to find cheaper alloys to make bushings out of via the power of physics simulation.
            These simulations are again built in the Julia programming language, which excels at high performance scientific computing.
            The Laurium Labs simulations, which can be run in a few hours, then provide direction for real-life experiments, which can take weeks to setup and execute.
          </p>
          <p>Learn more from the <LinkExternal href="https://arpa-e.energy.gov/sites/default/files/2020-11/03%20Neuvokas%20-%20Erik%20Kiilunen.pdf">ARPA-E Presentation.</LinkExternal></p>

        </Capa.content>
      </Capa.item>

    </Layout>
  )
}

export default PortfolioPage;
